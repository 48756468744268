import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FileUploadField } from "../../../components/FileUploadField/FileUploadField";
import { useFileInput } from "../../ApplyJobPage/hooks/fileInput.hook";
import { useApplicationForm as useForm } from "../../ApplyJobPage/hooks/form.hook";
import { useTelegram } from "../../../utils/Telegram.Provider";
import { Profile } from "../../../api/hooks/profiles/useGetProfile";
import UnicastBox from "../../../components/Custom/UnicastBox";
import ThemedButton from "../../../components/Custom/Button";
import { useUpsertProfile } from "../../../api/hooks/profiles/useMutateProfile";

const useStyles = makeStyles({
  container: {
    padding: "16px",
    paddingTop: "32px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "8px",
    marginBottom: "8px",
    width: "100%",
    background: "linear-gradient(to right, #C43A99, #5E0BA4)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  formContainer: {
    padding: "8px 8px",
    paddingBottom: "16px",
  },
  actions: {
    paddingTop: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface ProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  xProfile: string;
  portfolio: string;
  aboutMe: string;
}

interface ProfileEditionFormProps {
  profile?: Profile;
  onCloseEdition: () => void;
}

function ProfileEditionForm({
  profile,
  onCloseEdition,
}: ProfileEditionFormProps) {
  const { formData, handleChange } = useForm<ProfileForm>({
    firstName: profile?.firstName || "",
    lastName: profile?.lastName || "",
    email: profile?.email || "",
    xProfile: profile?.xProfile || "",
    portfolio: profile?.portfolio || "",
    aboutMe: profile?.aboutMe || "",
  });

  const { file, handleFileChange, clearFile } = useFileInput();

  const classes = useStyles();
  const telegram = useTelegram();

  const { mutate: submitProfile, isLoading } = useUpsertProfile();

  const isFormValid = React.useMemo(() => {
    return (
      ((!profile && !!file && file.type === "application/pdf") || !!profile) &&
      formData.firstName &&
      formData.lastName
    );
  }, [file, formData, profile]);

  const handleSubmit = React.useCallback(() => {
    const payload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) payload.append(key, value);
    });

    if (file) payload.append("resume", file);
    if (telegram) {
      const id = telegram?.initDataUnsafe?.user?.id;
      payload.append("telegramId", String(id));
    }

    submitProfile(
      {
        formData: payload,
        isUpdate: !!profile,
        telegramId: telegram?.initDataUnsafe?.user?.id!,
      },
      {
        onSuccess: () => {
          onCloseEdition();
        },
        onError: () => {},
      }
    );
  }, [formData, file, telegram, profile, submitProfile, onCloseEdition]);

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "start",
        }}
      >
        <UnicastBox className={classes.formContainer}>
          <Box className={classes.header}>
            <Typography variant="h6">Let's build your profile</Typography>
          </Box>
          <ProfileFormFields
            formData={formData}
            handleChange={handleChange}
            file={file}
            handleFileChange={handleFileChange}
            clearFile={clearFile}
            isResumeRequired={!profile}
          />
        </UnicastBox>
      </Box>
      <Box className={classes.actions}>
        <ThemedButton
          disabled={isLoading || !isFormValid}
          onClick={handleSubmit}
          sx={{
            width: "90%",
          }}
        >
          Save profile
        </ThemedButton>
      </Box>
    </Box>
  );
}

const ProfileFormFields = ({
  formData,
  handleChange,
  file,
  handleFileChange,
  clearFile,
  isResumeRequired,
}: {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  file: File | null;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFile: () => void;
  isResumeRequired?: boolean;
}) => (
  <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
    <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
      <TextField
        name="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.firstName.length > 25}
        size="small"
        fullWidth
        required
      />
      <TextField
        name="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        inputProps={{ maxLength: 25 }}
        error={formData.lastName.length > 25}
        size="small"
        fullWidth
        required
      />
    </div>
    <TextField
      name="email"
      label="Email"
      type="url"
      value={formData.email}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.email.length > 50}
      size="small"
      fullWidth
      required
    />
    <FileUploadField
      file={file}
      handleFileChange={handleFileChange}
      clearFile={clearFile}
      isResumeRequired={isResumeRequired}
    />
    <TextField
      name="xProfile"
      label="X Profile"
      type="url"
      value={formData.xProfile}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.xProfile.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="portfolio"
      label="Github, Linkedin or Portfolio URL"
      type="url"
      value={formData.portfolio}
      onChange={handleChange}
      inputProps={{ maxLength: 50 }}
      error={formData.portfolio.length > 50}
      size="small"
      fullWidth
    />
    <TextField
      name="aboutMe"
      label="About Me"
      multiline
      rows={4}
      value={formData.aboutMe}
      onChange={handleChange}
      inputProps={{ maxLength: 1000 }}
      error={formData.aboutMe.length > 1000}
      fullWidth
    />
  </div>
);

export default ProfileEditionForm;
