import React from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTelegram } from "../../utils/Telegram.Provider";
import EditIcon from "@mui/icons-material/Edit";
import { useGetProfile } from "../../api/hooks/profiles/useGetProfile";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import ProfilePhoto from "./components/ProfilePicture";

const useStyles = makeStyles({
  headerImage: {
    width: "100%",
    height: "auto",
  },
  profilePicture: {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
  },
  profileContainer: {
    width: "max-content",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
  },
  detailsContainer: {
    padding: "16px",
  },
  detailsCard: {
    padding: "16px",
    backgroundColor: "#121212",
    color: "#FFFFFF",
    borderRadius: "24px",
  },
  detailsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  details: {
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  divider: {
    backgroundColor: "white",
    height: "1px",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    marginLeft: "12% !important",
    marginRight: "12% !important",
  },
  textHelper: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "16px",
    color: "#FFB6EC",
  },
});

function ProfilePage() {
  const classes = useStyles();
  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  const [isImageLoading, setImageLoading] = React.useState<boolean>(true);

  const { data, isLoading } = useGetProfile(webApp?.initDataUnsafe?.user?.id);

  if (isLoading || isImageLoading) {
    // A hacky way to avoid layout shift when image is loading
    return (
      <>
        <LoadingIndicator />;
        <img
          src="assets/images/profile.png"
          alt="Descriptive Alt Text"
          style={{ display: "none" }}
          onLoad={() => setImageLoading(false)}
        />
      </>
    );
  }

  // TODO: Fix image loading & layout shift
  return (
    <Box>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        <img
          src="assets/images/profile.png"
          alt="Descriptive Alt Text"
          className={classes.headerImage}
          onLoad={() => setImageLoading(false)}
        />
        <Box className={classes.profileContainer}>
          <Box className={classes.profilePicture}>
            <ProfilePhoto
              telegramId={webApp?.initDataUnsafe?.user?.id! || 6301103029}
              userName={webApp?.initDataUnsafe?.user?.first_name! || "Najib"}
            />
          </Box>
          <Typography variant="h4">
            {webApp?.initDataUnsafe?.user?.username || "Create Your Profile"}
          </Typography>
        </Box>
      </Box>
      {!data?.profile && (
        <Box className={classes.textHelper}>
          <Typography variant="body1" sx={{}}>
            Fill in your profile for one click apply
          </Typography>
        </Box>
      )}
      <Box className={classes.detailsContainer}>
        <Box className={classes.detailsCard}>
          <Box className={classes.detailsHeader}>
            <Typography variant="h6">Profile Information</Typography>
            <IconButton
              color="inherit"
              onClick={() => navigate("/profile/edit")}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.details}>
            <Box
              style={{
                display: "flex",
                gap: "96px",
                alignItems: "center",
              }}
            >
              <InformationBox
                label={"First Name"}
                value={data?.profile?.firstName || ""}
              />
              <InformationBox
                label={"Last Name"}
                value={data?.profile?.lastName || ""}
              />
            </Box>
            <InformationBox
              label={"Email"}
              value={data?.profile?.email || ""}
            />
            <InformationBox
              label={"X Profile"}
              value={data?.profile?.xProfile || ""}
            />
            <InformationBox
              label={"Github, LinkedIn or Portfolio URL"}
              value={data?.profile?.portfolio || ""}
            />
            <InformationBox
              label={"About Me"}
              value={data?.profile?.aboutMe || ""}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const useHelperStyles = makeStyles({
  textBox: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  value: {
    color: "#AEAEAE",
  },
});

const InformationBox = ({ label, value }: { label: string; value: string }) => {
  const classes = useHelperStyles();
  return (
    <Box className={classes.textBox}>
      <Typography variant="body1">{label}</Typography>
      <Typography className={classes.value} variant="body1">
        {value}
      </Typography>
    </Box>
  );
};

export default ProfilePage;
